import React from "react";

import contactStyle from "./Contact.module.scss";

import { dispatchWPDownloadLinkClick } from "../../../utils/GTMEventDispatchers";

// Blog info bar short form.
export const BlogSubscribeFormReplacementMessage = () => (
  <div className={contactStyle.thanks}>
    <p>
      <span>Done!</span>Thank you so much for your subscription.
    </p>
  </div>
);

// Contact Form replacement message.
export const ContactFormReplacementMessage = () => (
  <div className={contactStyle.thanks}>
    <p>
      <span>Done!</span>Thanks so much for reaching out.
      <br />
      You'll hear back from us right away.
    </p>
  </div>
);

// Whitepaper conversion form replacement message.
export const WhitepaperLeadMagnetFormReplacementMessage = ({
  downloadLink
}) => (
  <div className={contactStyle.whitepaperDownload}>
    <h2>Thank You!</h2>
    <p>Here is your whitepaper ready for download.</p>
    <a
      href={downloadLink}
      download
      onClick={() => dispatchWPDownloadLinkClick()}
    >
      DOWNLOAD
    </a>
  </div>
);

export const EbookLeadMagnetFormReplacementMessage = ({ downloadLink }) => (
  <div className={contactStyle.whitepaperDownload}>
    <h2>Thank You!</h2>
    <p>Here is your E-book ready for download.</p>
    <a
      href={downloadLink}
      download
      onClick={() => dispatchWPDownloadLinkClick()}
    >
      DOWNLOAD
    </a>
  </div>
);
