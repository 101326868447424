import * as R from "ramda";
import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown/with-html";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { LinkRenderer } from "../../utils/utilities";

//components
import HubspotForm from "../../components/non-panels/forms/HubSpotForm";
import HubspotFormDownloadLink from '../../components/non-panels/forms/HubspotDownloadLink'
import { WhitepaperLeadMagnetFormReplacementMessage } from "../../components/non-panels/forms/replacementElements";
import SEO from "../../components/seo";
import useSetPageData from "../../utils/useSetPageData";

//styles
import s from "./whitepaper.module.scss";

const wpLens = R.lensPath(["data", "pageWhitePaperPost", "edges", 0, "node"]);
const wpImgLens = R.lensPath([
  "white_papers_media_image",
  "childImageSharp",
  "fluid"
]);

const getWhitepaper = R.view(wpLens);

const getWhitepaperImage = R.view(R.compose(wpLens, wpImgLens));

const getHeaderBackground = R.view(
  R.lensPath(["data", "headerBackground", "childImageSharp", "fluid"])
);

function stopFormAtFooter(setIsVisible) {
  const options = {
    root: null
  };

  return window => {
    const sentinel = window.document.querySelector(`.${s.sentinel}`);
    const observer = new window.IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    observer.observe(sentinel);

    return () => observer.disconnect();
  };
}

export default props => {
  const whitepaper = getWhitepaper(props);
  const wpImageFluid = getWhitepaperImage(props);
  const headerBackgoundFluid = getHeaderBackground(props);

  const [isVisible, setIsVisible] = useState(null);
  useSetPageData(props.location);

  useEffect(() => {
    return R.ifElse(
      R.isNil,
      R.always(() => null),
      stopFormAtFooter(setIsVisible)
    )(window);
  }, []);

  return (
    <>
      <SEO
        {...{
          title: `${whitepaper.SEO.tab_title}`,
          description: whitepaper.SEO.description
        }}
        meta={
          whitepaper.white_papers_media_image.publicURL
            ? [
                {
                  property: "og:image",
                  content: whitepaper.white_papers_media_image.publicURL
                }
              ]
            : []
        }
      />
      <div className={`${s.wpContainer}`}>
        <BackgroundImage
          fluid={[
            "linear-gradient(rgba(43, 32, 112, 0.405), rgba(43, 32, 112, 0.99))",
            headerBackgoundFluid
          ]}
          Tag="section"
          className={`${s.wpHeader}`}
        >
          <div className="container">
            <div className="row">
              <div className="col-md">
                <div className={s.headerTitleContent}>
                  <h4>Download the new whitepaper</h4>
                  <h1>{whitepaper.heading}</h1>
                </div>
                <div className={s.headerTextContent}>
                  <p>{whitepaper.SubHeading}</p>
                  <a
                    href="#form"
                    id="#button"
                    className={`btn btn-secondary btn-lg ${s.getButton}`}
                  >
                    Get the Whitepaper
                  </a>
                </div>
              </div>
              <div className="col-md"></div>
            </div>
          </div>
        </BackgroundImage>
        <article className="container">
          <div className="row">
            <div className={`${s.wpContent} col-md`}>
              <div className={s.imageWrapper}>
                <Img fluid={wpImageFluid} />
              </div>
              <ReactMarkdown
                className={s.textWrapper}
                source={whitepaper.content}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            </div>
            <div className="col-md">
              <div
                id="form"
                className={`${s.wpForm} ${isVisible ? s.abs : s.fixed}`}
              >
                <h4>Please fill out the form to download your copy</h4>
                <div className={s.formContainer}>
                  <HubspotFormDownloadLink
                    formId="431f4e43-590b-4aa7-aeb4-bccb4a47adf0"
                    replacementElement={
                      <WhitepaperLeadMagnetFormReplacementMessage
                        downloadLink={
                          whitepaper.downloadable_file_from_form
                            ? whitepaper.downloadable_file_from_form[0]
                                .downloadable_file.publicURL
                            : null
                        }
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
        <i className={`${s.sentinel}`}></i>
      </div>
    </>
  );
};

export const postQuery = graphql`
  query WhitePaper($id: String!) {
    logoRounded: file(relativePath: { eq: "logo-rounded-1536x361.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerBackground: file(relativePath: { eq: "outside_the_box.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pageWhitePaperPost: allStrapiPostWhitePapers(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          SEO {
            tab_title
            description
          }
          slug
          heading
          SubHeading
          content
          created_at
          downloadable_file_from_form {
            downloadable_file {
              publicURL
            }
            sales_pitch_one_liner
          }
          white_papers_media_image {
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
