import React, { useState, useEffect } from "react";
// import { Helmet } from "react-helmet";
import RotateLoader from "react-spinners/RotateLoader";

// import { debounce } from "../../../utils/utilities";

import HubSpotForm from "./BaseHubspotForm";
import contactStyle from "./Contact.module.scss";

/**
 * HubSpot form by default sets its width inline when first rendering.
 * It doesn't resize, and is tricky to target with css.
 * This function updates it's width manually.
 */
const updateFrameWidth = () => {
  const hbIframe = document.querySelector("iframe.hs-form-iframe");

  if (hbIframe != null) {
    hbIframe.width = "100%";
    hbIframe.style.width = "100%";
  }
};

// I am using the blog shortform as the default replacementElement.
const ThankYouMessage = () => (
  <div style={{ marginTop: "3rem" }} className={contactStyle.thanksDl}>
    <p>
      <span>Happy Reading!</span>
    </p>
  </div>
);

export default ({ formId, className, replacementElement, onSubmit }) => {
  // Track submission state.
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      let submitMessage =
        document.querySelector(".hs-form-iframe") !== null &&
        document
          .querySelector(".hs-form-iframe")
          .contentWindow.document.querySelector(".submitted-message");

      if (submitMessage && !isSubmitted) {
        setIsSubmitted(true);
      }

    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // useLayoutEffect(() => {
  //   // Add window listener to trigger a debounced, manual update of iframe width.
  //   window.addEventListener("resize", debounce(updateFrameWidth, 500));

  //   return () => window.removeEventListener("resize");
  // }, []);

  return (
    <div className={`${contactStyle.hbFormWrapper} ${className}`}>
      {/* <Helmet>
        This is probably the most problematic part of this.
            That the onSubmit requires jQuery is a little ridiculous
            But I have tried everthing I could think of, including a polyfilled
            jQuery element using a proxy for jQ specific methods, no dice.
        <script
          src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
          integrity="sha256-4+XzXVhsDmqanXGHaHvgh1gMQKX40OUvDEBTu8JcmNs="
          crossorigin="anonymous"
        ></script>
      </Helmet> */}
      {isSubmitted ? (
        replacementElement || <ThankYouMessage />
      ) : (
        <HubSpotForm
          portalId="8158070"
          formId={formId}
          onReady={updateFrameWidth}
          // onSubmit={
          //   handleSubmit
          // }
          loading={
            // Taken directly from react-bootstrap docs.
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <RotateLoader size={100} color={"#2a2070"} loading={true} />
            </div>
          }
        />
      )}
    </div>
  );
};
