// Event names must match what is configured in the google tag manager console.
// There should be the ability to set and update these in configuration.
// However, I have set defaults to their current values.
const {
  GTM_CONDENSED_FORM_SUBMISSION_EVENT_NAME,
  GTM_FULL_FORM_SUBMISSION_EVENT_NAME,
  GTM_WP_DOWNLOAD_LINK_EVENT_NAME
} = process.env;

/**
 * When the user fills in the condensed sign up form on the blog list views,
 * this fires the corresponding google tag event.
 */
export function dispatchCondensedFormSubmission() {
  const eventName =
    GTM_CONDENSED_FORM_SUBMISSION_EVENT_NAME || "BlogFormSubmission";
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName
  });
}

/**
 * When the user submits the full form on the whitepaper page in order to download the WP,
 * this fires the corresponding google tag manager event.
 */
export function dispatchFullFormSubmission() {
  const eventName =
    GTM_FULL_FORM_SUBMISSION_EVENT_NAME || "WhitepaperFormSubmit";
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName
  });
}

/**
 * When the user clicks the link to download the Whitepaper after filling out their information
 * this fires the corresponding google tag manager event.
 */
export function dispatchWPDownloadLinkClick() {
  const eventName = GTM_WP_DOWNLOAD_LINK_EVENT_NAME || "WhitepaperDownloadLink";
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName
  });
}
